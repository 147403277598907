.nav {
  background-color: #041e34;
  background-size: 80px 30px;
  height: calc(100vh);
}
.logo {
  line-height: 90px;
  padding-right: 20px;
  background-color: #0d253b;
}
.logo h1 {
  color: #ffffff;
  vertical-align: middle;
  text-align: center;
}
.ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #ffffff;
}
.ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  background: #ffffff;
}
.menu {
  background-color: #041e34;
  color: #bfcbd9;
  border: 0px;
}
.menu .nav-item {
  color: #bfcbd9;
}
